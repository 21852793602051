import React from "react"

import Layout from "../containers/Layout"

const NotFoundPage = () => (
  <Layout title='404: Not Found'>
    <div className='404 container'>
      <div className='row'>
        <div className='col-xs-12'>
          <h1>404 - NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist...</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
